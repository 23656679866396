:global(#root) .root {
  :global(.content) {
    display: flex;
    padding-right: 10px;
  }

  i:global(.icon:not(.close)) {
    display: none;
  }
}
