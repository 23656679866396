@import 'styles/_mixins';
@import './Statistics.utils';

$breakpoint1030: 1030px;

.root {
  display: flex;
  position: relative;
  z-index: 1;
}

.imageGallery {
  width: 642px;
  flex-shrink: 0;
  position: relative;

  @include breakpoint($breakpoint1030) {
    width: 400px;
  }
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  right: -10%;

  @include breakpoint-xl() {
    right: 0;
  }

  @include breakpoint($breakpoint1030) {
    justify-content: center;
  }
}

%column {
  width: 304px !important;
}

.firstColumn {
  @extend %column;

  height: 1290px;
  margin-right: 34px;
}

.secondColumn {
  @extend %column;

  height: 350px;
}
