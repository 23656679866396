@import 'styles/_mixins';

:global(#root) .root {
  --color: var(--color-black);
  --border-color: rgba(0, 0, 0, 0.1);
  &.lightTheme {
    --color: var(--color-light);
    --border-color: rgba(255, 255, 255, 0.1);
  }

  width: 300px;

  @include breakpoint-xs() {
    width: 100%;
  }

  .input input {
    padding: 16px 20px;
    border: 1px solid var(--border-color);
    font-family: var(--font-family);
    background-color: transparent;
    color: var(--color);
    border-radius: 20px;

    &::placeholder {
      color: var(--color);
    }
  }

  .icon {
    opacity: 1;

    &::before {
      color: var(--color);
    }
  }
}
