html {
  font-family: var(--font-family);
  font-size: 16px;
}

body {
  width: 100%;
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: var(--font-family);
}

h1,
h2,
h3 {
  font-family: var(--font-family);
}

::placeholder {
  font-family: var(--font-family);
}

.nowrap {
  white-space: nowrap;
}

#root {
  overflow-x: hidden;
}

.swiper-slide-visible > * {
  opacity: 1;
}
