@import 'styles/_mixins';

:global(#root) {
  & .table {
    background: transparent;
    margin-top: 0;
    border: none;
    border-collapse: collapse;

    & .tbody {
      // border-right: 15px solid transparent;
      // border-left: 15px solid transparent;
    }
  }

  & .headerCell {
    padding: 23px 25px;
    background: transparent;
    border: none;
    font-size: 12px;
    line-height: 150%;
    color: #5D5D5B
  }

  & .cell {
    padding: 22px 25px 18px 25px;
    font-size: 14px;
    line-height: 150%;
  }

  & .priceCell {
    font-weight: 700;
    vertical-align: middle;
    display: inline-block;

    &:first-of-type {
      margin-right: 6px;
    }
  }

  & .fromCell {
    font-weight: 700;
  }
}
