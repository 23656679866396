@import 'styles/_mixins';

.root {
  --color: var(--color-dark);
  --background-color: var(--color-light-grey);
  &.darkTheme {
    --color: var(--color-light);
    --background-color: var(--color-dark);
  }

  display: flex;
  flex-direction: column;
  row-gap: 60px;
  padding: 40px 60px 60px;
  background-color: var(--background-color);

  @include breakpoint-md() {
    padding: 40px 40px 60px;
  }
}

.topline {
  @include layout;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 80px;
  margin-bottom: 100px;

  &.onRootPage {
    margin-bottom: 0;
  }

  @include breakpoint-xl() {
    flex-direction: column;
    column-gap: 0;
    row-gap: 30px;
    width: max-content;
    min-width: auto;
  }

  @include breakpoint-sm() {
    width: 100%;
    min-height: 80px;
    margin-bottom: 40px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 60px;

  @include breakpoint-sm() {
    position: absolute;
    left: 40px;
    right: 40px;
    z-index: 110;
    flex-direction: column;
    align-items: initial;
    row-gap: 10px;
  }

  &Fixed {
    position: fixed;
  }
}

.containerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 60px;

  @include breakpoint-sm() {
    align-items: normal;
    column-gap: 20px;
  }
}

.iconGroup {
  display: flex;
  column-gap: 40px;
}

.icon {
  margin-right: 16px;

  &::before {
    padding: 14px;
    color: var(--color);
    cursor: pointer;
  }

  &Search::before {
    border: 1px solid rgba(101, 103, 107, 0.2);
    border-radius: 20px;
  }
}

:global(#root) .searchInput {
  @include breakpoint-sm() {
    width: 100%;
  }
}

:global(#root) .sidebar {
  width: 100%;
  padding: 140px 40px 0;
  color: var(--color);
  background-color: var(--background-color);
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  color: var(--color-light);
}

.hidden {
  opacity: 0 !important;
}
