@import 'styles/_mixins';

.root {
  position: relative;
  width: 100%;
  min-width: 536px;
  
  @include breakpoint-md() {
    min-width: initial;
  }
}

.image {
  width: 100%;
  border-radius: 20px;
}
