@import 'styles/_mixins';

.container {
  min-width: 320px;
  max-width: 1440px;
  min-height: 300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 60px;
  padding-left: 60px;

  @include breakpoint-xl() {
    padding-right: 20px;
    padding-left: 20px;
  }
}
