.root {
  display: flex;
  align-items: center;
}

.avatar {
  width: 36px;
  height: 36px;
}

.wallet {
  margin-left: 10px;
  font-weight: 700;
}
