@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
}

.mainInfo {
  display: flex;
  column-gap: 34px;

  @include breakpoint-md() {
    flex-direction: column;
  }
}

.tables {
  display: flex;
  margin-top: 40px;

  @include breakpoint-md() {
    flex-direction: column;
  }
}

.table {
  flex-grow: 1;
  width: 100%;
  &:first-of-type {
    margin-right: 34px;

    @include breakpoint-md() {
      margin-right: 0;
      margin-bottom: 34px;
    }
  }
}
