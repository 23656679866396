@import 'styles/_mixins';

:global(#root) .root {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  align-items: start;
  column-gap: 80px;
  width: 90%;
  margin: auto;

  @include breakpoint-lg() {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .uploader {
    display: flex;
    gap: 20px;

    @include breakpoint-xs() {
      flex-direction: column;
      align-items: center;
    }
  }

  .uploadField > * {
    @include breakpoint-xs() {
      margin: auto;
      text-align: center;
    }
  }

  .group {
    flex-direction: column;
    padding: 10px 0;

    &:first-of-type {
      padding-top: 0;
      border-bottom: 1px solid var(--border-color);
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .groupTitle {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .groupDescription {
    margin-bottom: 30px;
    font-size: 12px;
    color: var(--color-dark-grey);
  }
}
