@import 'styles/_mixins';
@import '../Main.utils';

$breakpoint900: 900px;
$pageContainerWidth: 1440px;
$basicLeftOffest: 120px;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: $basicLeftOffest * -1;
  width: 100%;
  position: relative;
  z-index: 1;

  @include breakpoint-xxl() {
    margin-left: calc(($pageContainerWidth + $basicLeftOffest - 100vw) * -1);
  }

  @include breakpoint($breakpoint900) {
    margin-left: 0;
  }
}

.imageContainer {
  width: 706px;

  @include breakpoint($breakpoint900) {
    display: none;
  }
}

.image {
  display: block;
}

.content {
  padding: 100px 0 100px 113px;
  width: 643px;
  flex-shrink: 0;

  @include breakpoint($breakpoint900) {
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 0;
    text-align: center;
  }
}

.title {
  @include h2;

  text-transform: capitalize;
  margin-bottom: 23px;
}

.message {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
  color: var(--color-dark);
  opacity: 0.6;
  margin-bottom: 55px;
}
