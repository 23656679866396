.root {
  flex-shrink: 0;
  width: 108px;
  height: 108px;
  position: relative;

  &Default {
    border-radius: 50%;
    background: url('./assets/default-avatar.png') no-repeat center;
    background-size: contain;
  }

  &.xxsSize {
    width: 32px;
    height: 32px;
  }

  &.xsSize {
    width: 48px;
    height: 48px;
  }

  &.smSize {
    width: 54px;
    height: 54px;
  }

  &.lgSize {
    width: 128px;
    height: 128px;
  }
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.verifyIcon {
  position: absolute;
  right: -5px;
  bottom: -5px;
}
