.root {
  width: 352px;
  height: 490px;
  border-radius: 21px;
  position: relative;
  overflow: hidden;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.content {
  backdrop-filter: blur(67px);
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -5px;
  left: -5px;
  bottom: -5px;
  padding: 21px 17px 25px;
  display: flex;
  align-items: center;
  transition-duration: 400ms;

  &.isHidden {
    opacity: 0;
  }
}

.nftName {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: var(--color-light);
  margin-bottom: 5px;
}

.ownerName {
  font-weight: 500;
  font-size: 14.4px;
  line-height: 1.7;
  color: var(--color-bright);
}

.textBlock {
  display: flex;
  flex-direction: column;
}

.avatar {
  width: 52px;
  height: 52px;
  object-fit: cover;
  margin-right: 20px;
}
