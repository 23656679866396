$leftOffset: 20px;

.root {
  background: var(--color-light);
  border: 1px solid rgba(101, 103, 107, 0.1);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px $leftOffset;
  gap: 10px;
  margin-bottom: 35px;
  position: relative;
}

.emailField {
  all: unset;
  text-align: left;
  width: 100%;
  background-position: 0 0;

  &::placeholder {
    color: var(--color-dark-grey);
    opacity: 1;
  }

  &:-webkit-autofill:hover,
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
  }
}

:global(#root) .button {
  border: 1px solid var(--color-bright);
  margin-right: 0 !important;
  flex-shrink: 0;

  &:hover {
    background-color: var(--color-bright) !important;
  }
}

.alert {
  position: absolute;
  left: $leftOffset;
  bottom: -10px;
  transform: translateY(100%);
  font-size: 13.5px;
  color: var(--color-red);
}
