:global(#root) .column {
  overflow-x: initial !important;
  pointer-events: none;
  touch-action: none;
}

.card {
  width: 430px !important;
  display: flex;
  justify-content: center;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
}

.nftContainer {
  pointer-events: all;
  touch-action: auto;

  &:hover {
    filter: drop-shadow(0px 0px 15px rgba(255, 162, 0, 0.8));
  }
}
