@import 'styles/_mixins';

.root {
  max-width: 360px;
  position: relative;
  color: var(--text-color);
}

.avatar {
  position: absolute;
  top: -140px;

  @include breakpoint-xl() {
    position: static;
    display: block;
    margin: 0 auto 20px;
  }
}

.username {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 700;
  color: var(--color-black);

  @include breakpoint-xl() {
    text-align: center;
  }
}

.headerInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.wallet {
  display: flex;
  gap: 20px;
}

.bio {
  margin: 30px 0;
}

.meta {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 20px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--border-color);
}

:global(#root) .root {
  .metaItem {
    margin: 0;
  
    * {
      font-family: var(--font-family);
    }

    :global(.value) {
      padding-bottom: 6px;
      font-size: 16px !important;
      font-weight: 700;
      color: var(--color-black);
    }

    :global(.label) {
      font-size: 14px;
      font-weight: 500;
      text-transform: none;
      color: var(--color-grey);
    }
  }
}
