.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 305px;
  height: 236px;
  padding: 0 75px;
  border: 1px solid #DEDEDE;
  border-radius: 8px;

  &Clickable {
    cursor: pointer;
  }
}

.title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
