.root {
  display: inline-block;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #606060;
  border-radius: 10px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(95deg, transparent 8%, rgba(189, 189, 190, 0.45) 18%, transparent 33%);
    background-size: 200% 100%;
    animation: 1.5s shimmer linear infinite;
  }
}

@keyframes shimmer {
  100% {
    background-position-x: -200%;
  }
}
