@import 'styles/_mixins';

.root {
  text-align: center;
  position: relative;
}

.textContainer {
  width: max-content;
  margin: auto;
  position: relative;
  z-index: 1;
}

.title {
  font-weight: 600;
  font-size: 80px;
  line-height: 0.9;
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint-lg() {
    font-size: 60px;
  }

  @include breakpoint-md() {
    font-size: 50px;
  }

  @include breakpoint-sm() {
    font-size: 38px;
  }
}

.titleAccentText {
  color: var(--color-light);
  transform: rotate(-3.79deg);
  background: var(--color-bright);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.25);
  width: max-content;
  padding: 42px 77px;
  margin-top: 11px;

  @include breakpoint-lg() {
    padding: 15px 33.5px;
  }

  @include breakpoint-md() {
    margin-top: 0;
    padding: 15px 25px;
  }
}

.message {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  color: var(--color-black);
  width: 605px;
  max-width: 100%;
  margin: 58px auto 60px;

  @include breakpoint-md() {
    width: 340px;
    font-size: 18px;
  }

  @include breakpoint-md() {
    font-size: 16px;
  }
}

.buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.button {
  margin-right: 0 !important;
}

.backgroundContainer {
  position: absolute;
  width: 1440px;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.lightBall1 {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
}

.lightBall2 {
  position: absolute;
  top: 150px;
  left: 0;
  transform: translateX(-70%);
}

.lightBall3 {
  position: absolute;
  top: 500px;
  left: 0;
  transform: translateX(-35%);
}

.lightBall4 {
  position: absolute;
  top: 200px;
  right: 0;
  transform: translateX(40%);
}

.lightBall5 {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(60%) translateY(-40%);
}

.jetton1 {
  width: 123px;
  position: absolute;
  top: -5px;
  left: 35px;
}

.jetton2 {
  width: 228px;
  position: absolute;
  top: -36px;
  right: -81px;
}

.jetton3 {
  width: 202px;
  position: absolute;
  top: 400px;
  left: 98px;
}

.jetton4 {
  width: 164px;
  position: absolute;
  top: 500px;
  right: 160px;
}

.secondPart {
  position: relative;
}

.jettonsImage {
  max-width: 100%;
  display: block;
  margin: auto;
}

.tickers {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.lightBall6 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-60%, -50%);
  z-index: -1;

  @include breakpoint-md() {
    display: none;
  }
}

.lightBall7 {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;

  @include breakpoint-md() {
    display: none;
  }
}
