@import 'styles/_mixins';

.root {
  padding: 40px 77px 47px 25px;

  @include breakpoint-md() {
    padding: 20px 13px;
  }
}

.text {
  font-size: 14px;
  line-height: 150%;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;

  &:first-of-type {
    margin-top: 0;
  }
}

.left {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}

.right {
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
}

.info {
  margin-top: 43px;
  width: 50%;

  @include breakpoint-md() {
    width: 100%;
  }
}

.inline {
  display: inline-block;
  vertical-align: middle;

  &:first-of-type {
    margin-right: 10px;
  }
}
