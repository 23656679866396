@import 'styles/_mixins';

.root {
  display: flex;
  justify-content: center;
  column-gap: 80px;
  
  @include breakpoint-xl() {
    flex-wrap: wrap;
    row-gap: 60px;
  }

  > :nth-child(1) { flex-basis: 20%; }
  > :nth-child(2) { flex-basis: 80%; }
}
