$iconfont-font: "iconfont";

@font-face {
    font-family: $iconfont-font;
    src: url("./iconfont.eot?080c97e487f1c3622e0f8d2f49fa86f3#iefix") format("embedded-opentype"),
url("./iconfont.woff2?080c97e487f1c3622e0f8d2f49fa86f3") format("woff2"),
url("./iconfont.woff?080c97e487f1c3622e0f8d2f49fa86f3") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: iconfont !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$iconfont-map: (
    "arrow": "\f101",
    "basket": "\f102",
    "coins": "\f103",
    "collections": "\f104",
    "info": "\f105",
    "logout": "\f106",
    "nft": "\f107",
    "nfts": "\f108",
    "plus": "\f109",
    "profile": "\f10a",
    "sale": "\f10b",
    "settings": "\f10c",
    "social-instagram": "\f10d",
    "social-linkedin": "\f10e",
    "social-twitter": "\f10f",
    "upload": "\f110",
    "wallet": "\f111",
);

.icon-arrow:before {
    content: map-get($iconfont-map, "arrow");
}
.icon-basket:before {
    content: map-get($iconfont-map, "basket");
}
.icon-coins:before {
    content: map-get($iconfont-map, "coins");
}
.icon-collections:before {
    content: map-get($iconfont-map, "collections");
}
.icon-info:before {
    content: map-get($iconfont-map, "info");
}
.icon-logout:before {
    content: map-get($iconfont-map, "logout");
}
.icon-nft:before {
    content: map-get($iconfont-map, "nft");
}
.icon-nfts:before {
    content: map-get($iconfont-map, "nfts");
}
.icon-plus:before {
    content: map-get($iconfont-map, "plus");
}
.icon-profile:before {
    content: map-get($iconfont-map, "profile");
}
.icon-sale:before {
    content: map-get($iconfont-map, "sale");
}
.icon-settings:before {
    content: map-get($iconfont-map, "settings");
}
.icon-social-instagram:before {
    content: map-get($iconfont-map, "social-instagram");
}
.icon-social-linkedin:before {
    content: map-get($iconfont-map, "social-linkedin");
}
.icon-social-twitter:before {
    content: map-get($iconfont-map, "social-twitter");
}
.icon-upload:before {
    content: map-get($iconfont-map, "upload");
}
.icon-wallet:before {
    content: map-get($iconfont-map, "wallet");
}
