@import 'styles/_mixins';

:global(#root) .root {
  display: flex;
  column-gap: 80px;
  width: max-content;
  margin: 0;
  box-shadow: none;
  border: none;
  background-color: transparent;

  @include breakpoint-xl() {
    column-gap: 60px;
  }

  @include breakpoint-md() {
    margin-top: 20px;
  }

  :global(.menu),
  :global(.item) {
    box-shadow: none;
    border: none;
    background-color: transparent !important;
  }

  .menuItem {
    display: flex;
    justify-content: space-between;
    height: max-content;
    padding: 14px 0;
    font-weight: 600;
    white-space: nowrap;
    color: var(--color-light);

    @include breakpoint-xl() {
      text-align: left;

      :nth-child(1) { flex-basis: 90%; }
      :nth-child(2) { flex-basis: 10%; }
    }

    @include breakpoint-lg() {
      :not(:global(.text)) {
        font-size: 14px;
      }

      &:last-child :global(.menu) {
        top: auto;
        bottom: 0;
      }
    }
  }

  .navItem {
    padding: 10px 0 !important;
    background-color: transparent;
    white-space: nowrap;

    @include breakpoint-lg() {
      padding: 6px 18px !important;
    }
  }

  .link {
    color: var(--color-dark-grey);

    &:hover {
      color: var(--color-light);
    }
  }
}
