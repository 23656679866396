@import 'styles/_mixins';

.root {
  @include breakpoint-md() {
    margin-top: 22px;
  }
}

.currentPrice {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.priceInfo {
  margin-bottom: 60px;

  @include breakpoint-md() {
    margin-bottom: 20px;
  }
}

.price {
  display: flex;
  align-items: flex-end;
}

.priceValue {
  font-size: 44px;
  line-height: 1;
  font-weight: 700;

  @include breakpoint-md() {
    font-size: 24px;
  }
}

.dollarValue {
  margin-left: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: var(--text-color)
}

.lastPrice {
  margin-top: 10px;
  font-size: 14px;
  line-height: 150%;
  color: var(--text-color);

  @include breakpoint-md() {
    margin-top: 20px;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}
