.root {
  background-color: var(--color-light);
  border-radius: 16px;
  padding: 10px 12px 20px 10px;
  width: 192px;
  position: relative;
  z-index: 1;
}

.cover {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 6.8px;
  display: block;
}

.content {
  padding-top: 32px;
  position: relative;
  text-align: center;
}

.avatar {
  width: 48px;
  height: 48px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.name {
  display: inline-flex;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 8px;
  justify-content: center;
  height: 2.6em;
  align-items: center;
}

.button {
  margin-right: 0 !important;
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
