.root {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.label {
  opacity: 0.6;
}

.price {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 2px;
  width: 100%;
  font-display: auto;
  font-size: 16px;
  font-weight: 700;
}
