@import 'styles/_mixins';

:global(#root) .root {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px 80px;
  width: 90%;
  margin: auto;

  @include breakpoint-lg() {
    grid-template-columns: 1fr;
  }

  @include breakpoint-xs() {
    width: 100%;
  }
}

.group {
  &:last-of-type {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--border-color);
  }

  &TypeUpload {
    width: 305px;
  }

  label:last-of-type {
    padding-bottom: 0;
  }
}

.uploader {
  > *:nth-child(2) {
    padding: 16px 16px 24px;
    border-radius: 20px;
    background-color: var(--color-light);
  }

  &Field {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.preview {
  width: 272px;
  height: 256px;
  border-radius: 8px;
}

.saveButton {
  justify-self: flex-start;
  grid-column: 2;

  @include breakpoint-lg() {
    grid-column: 1;
  }
}
