@import 'styles/_mixins';

.root {
  display: flex;
  justify-content: center;
  column-gap: 40px;

  @include breakpoint-xl() {
    flex-wrap: wrap;
  }

  > :nth-child(1) { flex-basis: 20%; }
  > :nth-child(2) { flex-basis: 80%; }
}

.results {
  @include breakpoint-xl() {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
  }
}

.buttonGroup {
  display: flex;
  column-gap: 10px;
  margin-bottom: 40px;
}
