@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
}

.info {
  display: flex;
  gap: 34px;

  @include breakpoint-md() {
    flex-direction: column;
  }
}
