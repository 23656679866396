:global(#root) .root {
  padding: 20px;
  border: none;
  border-radius: 20px;
  font-family: var(--font-family);
  font-size: 14px;
  background-color: rgba(217, 217, 217, 0.2);

  &::placeholder {
    color: var(--color-dark-grey);
  }

  &Invalid {
    background-color: var(--color-light-red);
  }
}
