@mixin layout() {
  width: 100%;
  min-width: 90%;
  max-width: var(--max-width);
  margin: auto;
}

@mixin text-overflow($row-count: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: $row-count;
  -webkit-box-orient: vertical;
}

@mixin breakpoint($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin breakpoint-xxl() {
  @include breakpoint(1400px) {
    @content;
  }
}

@mixin breakpoint-xl() {
  @include breakpoint(1200px) {
    @content;
  }
}

@mixin breakpoint-lg() {
  @include breakpoint(992px) {
    @content;
  }
}

@mixin breakpoint-md() {
  @include breakpoint(768px) {
    @content;
  }
}

@mixin breakpoint-sm() {
  @include breakpoint(600px) {
    @content;
  }
}

@mixin breakpoint-xs() {
  @include breakpoint(425px) {
    @content;
  }
}

@mixin rectangle($proportion: 100%) {
  &::before {
    display: block;
    padding-top: $proportion;
    content: '';
  }
}
