@import 'styles/_mixins';

.root {
  @include rectangle(160%);

  border-radius: 290px;
  overflow: hidden;
  filter: drop-shadow(0px 0px 134px rgba(0, 0, 0, 0.2));
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
