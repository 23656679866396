:global(#root) .root {
  :global(.slick-prev) {
    transform: translate(0, -50%) rotate(180deg);
  }

  :global(.slick-next) {
    right: 8px;
  }

  :global(.slick-arrow) {
    z-index: 1;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 23px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    font-size: initial;
    background-color: var(--color-light);

    &::before {
      content: initial;
    }

    :global(.icon) {
      margin: 0;
      transform: rotate(180deg);
      color: var(--color-dark);
    }

    &:global(.slick-disabled) {
      :global(.icon) {
        color: var(--color-grey);
      }
    }
  }

  :global(.slick-dots) {
    bottom: -60px;

    li {
      button::before {
        font-size: 16px;
        color: #dfdfdf;
        opacity: 1;
      }

      &:global(.slick-active) {
        button::before {
          color: var(--color-bright) !important;
        }
      }
    }
  }
}
