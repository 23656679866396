@import 'styles/_mixins';

$tablet-breakpoint: 600px;

:global(#root) .root {
  --bg-color: transparent;
  --text-color: var(--color-dark);
  --active-color: var(--color-bright);
  &.darkTheme {
    --bg-color: var(--color-dark);
    --text-color: var(--color-light);
  }

  display: flex;
  gap: 20px;

  @include breakpoint($tablet-breakpoint) {
    flex-direction: column;
  }

  .menu {
    display: flex;
    align-items: center;
    column-gap: 60px;
    width: max-content;
    margin: 0;
    margin-left: -20px;
    border: none;
    box-shadow: none;
    background-color: var(--bg-color);

    @include breakpoint-xxl() {
      column-gap: 20px;
    }

    @include breakpoint($tablet-breakpoint) {
      flex-direction: column;
      align-items: normal;
      column-gap: 0;
      row-gap: 20px;

      > .menuItem {
        text-align: left;
      }
    }
  }

  .menuItem {
    display: flex;
    flex-direction: row;
    padding: 15px 20px;
    font-weight: 700;
    background-color: var(--bg-color) !important;
    color: var(--text-color) !important;

    &:hover,
    &:global(.active),
    &.dropdownActive {
      color: var(--active-color) !important;
    }

    @include breakpoint($tablet-breakpoint) {
      font-size: 14px;
    }
  }

  .subMenu {
    left: auto;
    right: 0;
    background-color: var(--color-light);
    padding: 20px 10px;
    border: none;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 15%;
      width: 35px;
      height: 35px;
      border-radius: 10px;
      background-color: var(--color-light);
      transform: translateY(-25%) rotate(45deg);
      touch-action: none;
      pointer-events: none;
    }

    @include breakpoint($tablet-breakpoint) {
      top: -15px !important;
      left: 100%;
      right: auto;

      &::before {
        top: 30px;
        left: -10px;
        right: initial;
      }
    }

    .menuHeader {
      text-transform: initial;
    }

    .menuItem {
      font-size: 16px !important;
      font-weight: 500 !important;
      background-color: var(--color-light) !important;
      color: var(--color-dark-grey) !important;

      &:hover,
      &:global(.active),
      &.dropdownActive {
        color: var(--active-color) !important;
      }
    }
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .connectButton {
    align-self: flex-start;
    white-space: nowrap;
  }
}
