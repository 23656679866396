@import 'styles/_mixins';

:global(#root) .root {
  display: flex;
  flex-direction: column;

  .menu {
    padding: 0 40px;
    border-bottom: none;

    @include breakpoint-xl() {
      margin: auto;
    }

    @include breakpoint-xs() {
      padding: 0;
    }
    
    :global(.item) {
      padding-left: 0;
      padding-right: 60px;
      font-weight: 500;
      text-transform: capitalize;

      @include breakpoint-md() {
        padding-right: 20px;
      }

      &:hover {
        color: var(--color-bright);
        border-color: transparent;
      }
    }

    :global(.item.active) {
      position: relative;
      font-weight: 700;
      color: var(--color-bright);
      border-color: transparent;

      &:before {
        content: '';
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        display: block !important;
        width: 40px;
        height: 3px;
        background-color: var(--color-bright);
      }
    }
  }

  .tab {
    margin: 0;
    padding: 40px;
    border: none;
    box-shadow: none;
    background-color: var(--color-light-grey);

    @include breakpoint-xl() {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include breakpoint-xs() {
      padding: 40px 0;
    }
  }

  .button {
    margin-top: 60px;
  }
}
