:root {
  --max-width: 1920px;

  --font-family: 'Montserrat', sans-serif;

  --color-dark: #080f2b;
  --color-black: #000;
  --color-light: #fff;
  --color-bright: #ffa300;
  --color-grey: #93989a;
  --color-light-grey: #f5f7fa;
  --number-color: #4a5568;
  --color-dark-grey: #777e91;
  --color-beige: rgba(255, 163, 0, 0.05);
  --color-red: #ee3232;
  --color-light-red: rgba(238, 50, 50, 0.2);
  --color-dark-card: #1b1b1b;

  --text-color: #65676b;
  --border-color: #e6e8ec;

  --card-width: 304px;
  --card-gap: 34px;
}
