:global(#root) .root {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 310px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .title {
    margin: 0;
    font-size: 24px;
  }

  .button {
    display: flex;
  }
  
  .filter {
    border-radius: 14px;
  }

  .filterTitle {
    display: flex;
    justify-content: space-between;
    font-family: var(--font-family);
    text-transform: capitalize;
    color: var(--color-black);
  }

  .content {
    padding: 20px;
  }
}
