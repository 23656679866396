@import 'styles/_mixins';

.root {
  font-family: var(--font-family);
  font-size: 16px;
  background-color: var(--color-light-grey);
}

.page {
  width: 90%;
  min-height: 300px;
  margin: auto;
  padding: 80px 40px;
  line-height: normal;

  @include breakpoint-xl() {
    width: 100%;
    padding: 80px 20px;
  }
}
