@import 'styles/_mixins';

@mixin h2($color: var(--color-dark)) {
  font-weight: 700;
  font-size: 44px;
  line-height: 1.5;
  margin-bottom: 80px;
  color: $color;

  @include breakpoint-md() {
    font-size: 38px;
    margin-bottom: 60px;
  }

  @include breakpoint-sm() {
    font-size: 32px;
    margin-bottom: 40px;
  }
}
