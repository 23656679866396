.root {
  &:not(.rootUploaded) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 30px;
    border: 2px dashed rgba(101, 103, 107, 0.5);
    border-radius: 20px;
    color: var(--color-dark-grey);

    &.rootInvalid {
      background-color: var(--color-light-red);
      border: none;
    }
  }

  &Uploaded {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: flex-start;
    gap: 10px;

    &.rootInvalid {
      .fileInfo {
        border-radius: 20px;
        background-color: var(--color-light-red);
      }
    }
  }
}

.icon::before {
  font-size: 20px;
}

.label {
  margin-top: 16px;
  margin-bottom: 32px;
  font-size: 12px;
  text-align: center;
}

:global(#root) .button {
  position: relative;
  justify-self: flex-start;
  white-space: nowrap;
}

.input {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  
  input[type=file],
  input[type=file]::-webkit-file-upload-button {
    cursor: pointer; 
  }
}

.fileInfo {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  padding: 14px 16px;
  font-size: 14px;
  font-weight: 500;
  word-break: break-word;
}
