@import 'styles/_mixins';

:global(#root) .root {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: start;
  gap: 50px 80px;
  width: 90%;
  margin: auto;

  @include breakpoint-lg() {
    grid-template-columns: 1fr;
  }

  @include breakpoint-xs() {
    width: 100%;
  }
}

.container {
  @include breakpoint-lg() {
    grid-row: 1;
  }
}

.group {
  &TypeUpload {
    width: 80%;

    @include breakpoint-xl() {
      width: 100%;
    }
  }

  &TypeNumeric {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    column-gap: 20px;

    @include breakpoint-xl() {
      grid-template-columns: 1fr;
    }
  }
}

.saveButton {
  justify-self: flex-start;
  grid-column: 2;

  @include breakpoint-lg() {
    grid-column: 1;
  }
}
