@import 'styles/_mixins';

%border {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  z-index: 10;

  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
}

.root {
  width: 1440px;
  max-width: 100%;
  position: relative;

  &:before {
    @extend %border;

    content: '';
    top: 0;
  }

  &:after {
    @extend %border;

    content: '';
    bottom: 0;
  }
}

.marquee {
  padding: 64px 0;

  @include breakpoint-sm() {
    padding: 30px 0;
  }
}

.marqueeItem {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
}

.image {
  max-height: 100%;
  max-width: 100%;
  display: block;
}

.shade {
  width: 200px;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(90deg, var(--color-light-grey), transparent);
  z-index: 5;
  pointer-events: none;
  touch-action: none;

  @include breakpoint-xxl() {
    width: 12%;
  }

  &:first-child {
    left: -5px;
  }

  &:last-child {
    right: -5px;
    transform: rotate(180deg);
  }
}
