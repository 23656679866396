@import 'styles/_mixins';
@import '../Main.utils';

.root {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  background-position: center;
  background-size: cover;
  border-radius: 30px;
  padding: 90px 50px;
  text-align: center;
  position: relative;
  overflow: hidden;

  @include breakpoint-md() {
    padding: 70px;
  }

  @include breakpoint-sm() {
    padding: 50px 20px;
  }
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.title {
  @include h2;

  text-align: center;
  color: var(--color-light);
  margin-bottom: 35px;
}

.message {
  max-width: 600px;
  color: var(--color-light);
  margin: auto;
  opacity: 0.6;
  margin-bottom: 60px;

  @include breakpoint-sm() {
    margin-bottom: 40px;
  }
}

.link {
  white-space: nowrap;
}
