@import 'styles/_mixins';

.root {
  display: flex;
  justify-content: center;
  column-gap: 60px;

  @include breakpoint-xxl() {
    flex-wrap: wrap;
    row-gap: 40px;
  }

  > :nth-child(1) { flex-basis: 20%; }
  > :nth-child(2) { flex-basis: 80%; }
}
