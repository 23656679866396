@import 'styles/_mixins';

.root {
  display: flex;
  gap: 14px;
  color: var(--color-light-grey);
  width: 100%;
  align-items: center;

  .avatar {
    @include breakpoint-sm() {
      width: 35px;
      height: 35px;
    }
  }
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 6px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  line-height: 1;
}

.name {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;

  @include breakpoint-sm() {
    font-size: 16px;
  }
}

.priceChange {
  font-weight: 500;
  font-size: 12px;
  color: var(--number-color);

  &.negative {
    color: #e23434;
  }

  &.positive {
    color: #42a846;
  }
}

.floorPrice {
  font-weight: 500;
  font-size: 14px;
  color: var(--text-color);
}

.floorPriceValue {
  font-weight: 700;
  font-size: 12px;
  color: var(--color-light);
  white-space: nowrap;
  margin-left: 8px;
}

.floorCurrency {
  margin-left: 3px;
  text-transform: uppercase;
}

.price {
  display: flex;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  text-align: right;
  color: var(--color-light);
}

.priceCurrency {
  font-size: 12px;
  margin-left: 6px;
}
