@import 'styles/_mixins';
@import '../Main.utils';

$breakpoint1320: 1320px;
$breakpoint810: 810px;

.root {
  width: 1183px;
  max-width: 100%;
  margin: 0 auto;
}

.title {
  @include h2;

  text-align: center;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(345px, 370px));
  justify-content: space-between;
  gap: 10px;

  @include breakpoint($breakpoint1320) {
    grid-template-columns: repeat(2, minmax(345px, 370px));
    justify-content: center;
    gap: 37px;
  }

  @include breakpoint($breakpoint810) {
    grid-template-columns: minmax(320px, 370px);
    grid-template-rows: auto;
  }
}

.card:last-child {
  @include breakpoint($breakpoint1320) {
    grid-column: 1 / 3;
  }

  @include breakpoint($breakpoint810) {
    grid-column: unset;
  }
}
