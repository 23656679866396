:global(#root) .root {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  * {
    color: var(--color-dark-grey);
  }

  .section:not(:last-child)::after {
    content: '/';
    margin: 0 8px;
  }

  .link:hover,
  .active {
    color: var(--color-light);
  }
}
