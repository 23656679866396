:global(#root) .root {
  --slides-amount: 1;

  position: relative;
  left: calc(var(--card-gap) / 2);
  width: calc(var(--slides-amount) * (var(--card-width) + var(--card-gap)));

  :global(.slick-list) {
    border: 1px solid transparent;
  }

  :global(.slick-track) {
    margin: inherit;
  }

  :global(.slick-slide) {
    margin-right: var(--card-gap);
  }
}
