.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: 42px;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
  }
}

.buttonsGroup {
  display: flex;
  gap: 20px;
}
