@import '../Main.utils';

.root {
  .slide {
    width: 305px;
    display: flex;
    justify-content: center;
  }
}

.title {
  @include h2;

  color: var(--color-light);
}
