.root {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  width: var(--card-width);
  height: 396px;
  padding: 18px;
  border-radius: 8px;

  &.lightTheme {
    background-color: var(--color-light);
  }

  &.darkTheme {
    background-color: var(--color-dark-card);
  }
}

.images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  align-items: stretch;
  gap: 10px;
  flex-grow: 1;

  .image:nth-child(1) {
    grid-row: 1 / 4;
    grid-column: 1;
  }
  .image:nth-child(2) {
    grid-row: 1;
    grid-column: 2;
  }
  .image:nth-child(3) {
    grid-row: 2;
    grid-column: 2;
  }
  .image:nth-child(4) {
    grid-row: 3;
    grid-column: 2;
  }

  &.imagesAmount4 {
    .image:not(:first-child) {
      width: 100%;
      max-height: 94px;
    }
  }

  &.imagesAmount3 {
    grid-template-rows: repeat(2, 1fr);

    .image:nth-child(1) {
      grid-row: 1 / 3;
      grid-column: 1;
    }
    .image:nth-child(2) {
      grid-row: 1 / 2;
      grid-column: 2;
    }
    .image:nth-child(3) {
      grid-row: 2 / 3;
      grid-column: 2;
    }
  }

  &.imagesAmount2 {
    grid-template-rows: 1fr;

    .image:nth-child(1) {
      grid-row: 1;
      grid-column: 1;
    }
    .image:nth-child(2) {
      grid-row: 1;
      grid-column: 2;
    }
  }

  &.imagesAmount1 {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    .image:nth-child(1) {
      grid-row: 1;
      grid-column: 1;
      justify-self: stretch;
      max-height: 300px;
    }
  }
}

.image {
  border-radius: 8px;
  object-fit: cover;
}

.container {
  display: flex;
  gap: 40px;
}
