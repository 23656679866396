@import 'styles/_mixins';

:global(#root) .root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1656px;
  padding: 6px 10px;
  border-radius: 26px;
  border: 1px solid rgba(0, 0, 0, 0.10);

  @include breakpoint-md() {
    width: 304px;
    margin: auto;
  }

 .button {
    display: flex;
    column-gap: 10px;
    cursor: default;
  }

  .icon {
    &:not(:global(.disabled)) {
      cursor: pointer;
    }

    &Reset {
      margin: 0;
      font-size: 16px;
      opacity: 1;
    }
  }

  .input {
    padding: 13px 20px;
    font-size: 16px;
    outline: none;

    &:empty::before {
      content: attr(data-placeholder);
      font-weight: 400;
      color: var(--color-dark-grey);
    }
  }
}
