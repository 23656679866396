.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(149, 145, 145, 0.3);
  
  &.lightTheme {
    background-color: #627EEA;
  }
}
