@import 'styles/_mixins';

:global(#root) {
  .input input {
    width: auto;
  }

  .checkbox {
    grid-row: 1 / 3;
    grid-column: 2;
    justify-self: end;
    align-self: center;

    @include breakpoint-md() {
      grid-row: 2;
      grid-column: 1;
      justify-self: start;
    }
  }
}
