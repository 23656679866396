.root {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.content {
  padding: 14px 20px 0;
}

.button {
  align-self: flex-start;
}
