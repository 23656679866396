:global(#root ~ .modals) {
  .root {
    display: flex;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    margin: 0;
    border: 1px solid rgba(34, 36, 38, 0.15);
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      margin-left: -1px;
    }

    &::before {
      color: var(--color-dark-grey);
    }

    &:hover::before {
      color: #000;
    }
  }
}
