.root {
  display: grid;
  gap: 14px;
  padding-bottom: 30px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;

  &Required::after {
    content: '*';
    color: var(--color-red);
  }

  :global(.icon) {
    margin-left: 10px;
  }
}

.description {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-dark-grey);

  &:last-child {
    margin-bottom: 12px;
  }
}

.error {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-red);
}
