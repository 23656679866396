.navigation {
  display: flex;
  gap: 20px;
  margin: 50px auto 0;
  width: max-content;
}

%button {
  all: unset;

  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-light);
  border: 1px solid var(--color-light);
  border-radius: 50%;
  cursor: pointer;

  &:not(.navButtonDisabled):hover {
    color: var(--color-bright);
    border-color: var(--color-bright);
  }

  &.navButtonDisabled {
    opacity: 0.7;
    cursor: default;
  }
}

.buttonPrev {
  @extend %button;
}

.buttonNext {
  @extend %button;

  transform: rotate(180deg);
}

.iconArrow {
  color: inherit;
}
