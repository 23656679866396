.root {
  text-align: center;
  background-color: var(--color-light);
  padding: 50px 28px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 20px;

  .icon {
    font-size: 60px;
    margin-bottom: 32px;
    margin-right: 0;

    &.walletIcon {
      color: #8358ff;
    }

    &.nftIcon {
      color: #10b981;
    }

    &.saleIcon {
      color: #ef4444;
    }
  }
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--color-black);
  margin-bottom: 20px;
}

.message {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--number-color);
  width: 370px;
  max-width: 100%;
  margin: auto;
}
