:global(#root) .root {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid rgba(101, 103, 107, 0.1);
  border-radius: 20px;
  font-family: var(--font-family);
  background-color: var(--color-light);

  &::placeholder {
    font-size: 14px;
    color: var(--color-grey);
  }

  :global(.icon) {
    color: var(--color-grey);
  }

  :global(.menu) {
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &Invalid {
    background-color: var(--color-light-red);
  }
}
