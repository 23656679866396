:global(#root ~ .modals) {
  .root {
    max-width: 400px;
  }

  h3 {
    text-align: center;
  }

  .content {
    display: flex;
    justify-content: center;
  }

  .actions {
    display: flex;
    justify-content: space-around;
    padding: 0 24px 30px;
    border: none;
    background-color: transparent;
  }

  .button {
    padding: 16px 42px;
    font-family: var(--font-family);
    font-weight: 600;
    background-color: var(--color-bright) !important;
    color: var(--color-light) !important;
    border-radius: 30px;
    box-shadow: none;

    &:hover {
      filter: contrast(150%);
    }
  }
}
