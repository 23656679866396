@import 'styles/_mixins';

.root {
  position: absolute;
  top: 22px;
  right: 29px;
  display: flex;
  align-items: center;
  padding: 11px 18px;
  background: var(--color-light);
  border-radius: 23px;
  border: 1px solid #dedede;
  cursor: pointer;

  @include breakpoint-md() {
    top: 14px;
    right: 13px;
  }
}

.icon {
  width: 16px !important;
  height: 16px !important;
  margin: 0 !important;
}

.amount {
  margin-left: 8px;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
}