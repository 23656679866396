:global(#root) .root {
  --bg-color: transparent;
  --color: var(--color-dark);
  --border-color: var(--color-dark);

  &.solid {
    &.darkTheme {
      --bg-color: var(--color-dark);
      --color: var(--color-light);
    }
    &.lightTheme {
      --bg-color: var(--color-beige);
      --color: var(--color-bright);
    }
    &.brightTheme {
      --bg-color: var(--color-bright);
      --color: var(--color-light);
    }
  }
  &.outlined {
    &.lightTheme {
      --border-color: var(--color-light);
    }
    &.fullLightTheme {
      --color: var(--color-light);
      --border-color: var(--color-light);
    }
    &.brightTheme {
      --border-color: var(--color-bright);

      &:hover {
        background-color: rgba(255, 163, 0, 0.05) !important;
      }
    }
  }
  &.invalid {
    &.darkTheme,
    &.lightTheme,
    &.brightTheme {
      --border-color: var(--color-light-red);
    }
  }

  &.solid.lightTheme {
    padding: 10px 30px;
    text-decoration: underline;

    &:hover {
      filter: none;
      text-decoration: none;
    }
  }

  &.outlined {
    box-shadow: 0 0 0 1px var(--border-color) inset !important;

    &:not(.fullLightTheme):hover {
      box-shadow: 0 0 1px 1px var(--border-color) inset !important;
    }

    &.fullLightTheme:hover {
      --color: var(--color-bright);
      --border-color: var(--color-bright);
    }
  }

  &.largeSize {
    padding: 16px 42px;
    font-size: 16px;
  }

  &.mediumSize {
    padding: 16px 20px;
  }

  &.smallSize {
    padding: 8px 30px;
  }

  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  background-color: var(--bg-color) !important;
  color: var(--color) !important;
  border-radius: 30px;

  &:hover {
    filter: contrast(150%);
  }
}
