@import 'styles/_mixins';
@import '../Main.utils';

$breakpoint1350: 1350px;
$breakpoint900: 900px;

.header {
  @include h2;

  color: var(--color-light);
}

.collection {
  display: grid;
  grid-template-columns: repeat(3, 394px);
  grid-template-rows: repeat(4, auto);
  grid-auto-flow: column;
  justify-content: space-between;
  gap: 6px 10px;

  @include breakpoint($breakpoint1350) {
    grid-template-columns: repeat(2, minmax(394px, 500px));
    grid-template-rows: repeat(6, auto);
    column-gap: 20px;
  }

  @include breakpoint($breakpoint900) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-auto-flow: unset;
    gap: 0;
  }
}

.item {
  padding: 24px 24px 24px 0;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid rgba(101, 103, 107, 0.3);

  @include breakpoint-sm() {
    padding-right: 0;
  }
}

.itemNumber {
  width: 50px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  color: var(--color-light-grey);

  @include breakpoint-sm() {
    width: 25px;
    text-align: left;
  }
}

.prelaoder {
  height: 54px;
}

:global(#root) .dropdown {
  color: var(--color-bright);

  :global(.menu) {
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
    padding: 32px;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 15%;
      transform: translateY(-25%) rotate(45deg);
      width: 35px;
      height: 35px;
      border-radius: 10px;
      background-color: var(--color-light);
      touch-action: none;
      pointer-events: none;
    }
  }

  :global(.item) {
    color: var(--color-dark-grey);
    padding: 10px 0 !important;
    font-size: 25px;
    font-weight: normal;
    line-height: 1.5;
    white-space: nowrap;
    background: none;

    &:hover,
    &:global(.active) {
      color: var(--color-bright);
    }
  }

  :global(.icon) {
    display: inline-block;
    height: auto;
    vertical-align: middle;
    font-size: 40px;
  }
}
