@import 'styles/_mixins';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: var(--card-gap);
  max-width: calc(5 * var(--card-width) + 4 * var(--card-gap));
  
  @include breakpoint-xl() {
    justify-content: center;
  }

  .link {
    &, &:hover {
      color: inherit;
    }
  }
}
