@import 'styles/_mixins';
@import '../Main.utils';
@import './OnSale.utils';

.root {
  width: max-content;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @include breakpoint($breakpoint680) {
    width: 90%;
  }

  @include breakpoint-sm() {
    width: 100%;
  }
}

.header {
  @include h2;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  all: inherit;
  margin: 0;
}

.link {
  color: inherit;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.1;
  white-space: nowrap;
  border-bottom: 1px solid transparent;

  &:hover {
    color: var(--color-bright);
    border-bottom: 1px solid var(--color-bright);
  }
}
