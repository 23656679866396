@import 'styles/_mixins';

.root {
  display: grid;
  grid-template-areas: 
    'title link'
    'nfts nfts';
  gap: 30px;

  @include breakpoint-md() {
    grid-template-areas: 
      'title'
      'nfts'
      'link';
  }
}

.title {
  grid-area: title;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.link {
  grid-area: link;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: right;
  color: var(--color-bright);

  &:hover {
    color: var(--color-bright);
  }

  @include breakpoint-md() {
    text-align: left;
  }
}

.nfts {
  grid-area: nfts;
  display: flex;
  overflow-y: auto;

  @include breakpoint-md() {
    display: block;
    overflow: initial;
  }
}

.card {
  margin-right: 28px;
  position: relative;

  @include breakpoint-md() {
    margin: 0 15px; 
  }
}
