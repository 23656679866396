@import 'styles/_mixins';

.collapsed {
  --row-limit: 1;

  @include text-overflow(var(--row-limit));
}

.readMore {
  font-size: 14px;
  font-weight: 700;
  color: var(--color-bright);
  cursor: pointer;
}
