.root {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  width: 100%;
}

.label {
  width: 100%;
  text-align: center;
}

:global(#root) .root {
  .input input {
    width: 100px;
    padding: 10px 14px;
  }

  :global(.ui.label) {
    padding: 10px 14px;
  }
}
