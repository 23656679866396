@import 'styles/_mixins';

:global(#root ~ .modals) .root {
  max-width: 480px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    img {
      padding: 0 !important;
    }

    @include breakpoint-sm() {
      font-size: 12px;

      h3 {
        font-size: 14px;
      }
    }
  }

  .header {
    margin-bottom: 0;
  }

  .description {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    p {
      margin: 0;
    }
  }

  .link {
    padding: 10px 30px;
    border-radius: 30px;
    font-weight: 700;
    color: var(--color-light);
    background-color: var(--color-bright);

    @include breakpoint-xs() {
      padding: 10px;
    }
  }

  .button {
    padding: 10px 20px;
    box-shadow: 0 0 0 1px var(--color-bright) inset;
  }
}
