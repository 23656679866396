@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include breakpoint-md() {
    margin-left: 0;
    margin-top: 28px;
  }
}

.club {
  display: flex;
}

.author {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-bright);
}

.name {
  margin-top: 10px;
  font-size: 44px;
  font-weight: 700;
  line-height: 1;
}

.description {
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
}

.users {
  display: flex;
  flex-grow: 1;
  gap: 34px;
  width: 100%;
  margin: 34px 0;

  @include breakpoint-md() {
    flex-direction: column;
  }
}

.userTitle {
  margin-bottom: 10px;
  font-size: 12px;
}
