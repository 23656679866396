.root {
  height: 100%;
  border-radius: 16px;
  border: 1px solid rgba(207, 219, 213, 0.6);
}

.header {
  padding: 20px 25px;
  font-size: 18px;
  line-height: 150%;
  font-weight: 700;
  border-bottom: 1px solid #dedede;
}

.content {
  max-height: 430px;
  overflow: auto;
}
