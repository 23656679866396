.root {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  margin-bottom: 16px;
}

.title {
  margin-bottom: 10px;
}

.section {
  display: flex;
  column-gap: 10px;
}

.address {
  font-size: 14px;
  color: var(--color-dark-grey);
}

:global(.icon).icon {
  font-size: 16px;
  color: var(--color-bright);
}

.label {
  margin-bottom: 6px;
  font-size: 12px;
  color: var(--color-dark-grey);
}

.balance {
  margin-bottom: 0;
  font-size: 24px;
}

:global(#root) .icon {
  width: 30px;
  height: 30px;
}
