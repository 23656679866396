@import 'styles/_mixins';

:global(#root) .root {
  margin: 0 auto 80px;

  @include breakpoint-md() {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .title {
    margin: 34px 0;
    font-size: 16px;
    font-weight: 500;
  }

  .button {
    display: block;
    margin-top: 60px;
  }
}
