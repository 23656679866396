@import 'styles/_mixins';

$breakpoint800: 800px;

%filledBackground {
  background-color: #121212;
}

.introSection {
  margin-bottom: 100px;

  @include breakpoint-md() {
    margin-bottom: 50px;
  }
}

.pageSection {
  margin-bottom: 170px;

  @include breakpoint-md() {
    margin-bottom: 85px;
  }
}

.partnersSection {
  @extend .pageSection;

  width: min-content;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.communitySection {
  transform: translateY(35%);
  position: relative;
  z-index: 1;
}

.statisticsSection {
  @extend .pageSection;
  @extend %filledBackground;

  overflow: hidden;
}

.lightBall1 {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 50%);
}

.lightBall2 {
  position: absolute;
  top: 50px;
  right: 50px;

  @include breakpoint($breakpoint800) {
    top: -350px;
    right: -350px;
  }
}

.lightBall3 {
  position: absolute;
  left: -250px;
  top: -250px;
}

.lightBall4 {
  position: absolute;
  left: -100px;
  top: -100px;
}

.lightBall5 {
  position: absolute;
  bottom: 0;
  right: -40px;
}

.statisticsContainer {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  position: relative;
}

.bottonContainer {
  position: relative;
}

.filledContainer {
  @extend .pageSection;
  @extend %filledBackground;

  position: relative;
  z-index: 1;
  padding: 100px 0;

  @include breakpoint-md() {
    padding: 50px 0;
  }
}

.trendingNftSection {
  @extend .pageSection;

  width: 1440px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}
