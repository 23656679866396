@import 'styles/_mixins';
@import '../Main.utils';

$breakpoint1460: 1460px;
$breakpoint1350: 1350px;
$breakpoint1040: 1040px;
$breakpoint950: 950px;
$breakpoint900: 900px;
$breakpoint720: 720px;
$breakpoint680: 680px;
$breakpoint450: 450px;

.root {
  width: max-content;
  max-width: 100%;
  margin: 0 auto;

  @include breakpoint($breakpoint450) {
    width: 100%;
  }
}

.header {
  @include h2;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.title {
  all: inherit;
  margin: 0;
}

.link {
  color: inherit;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.1;
  white-space: nowrap;
  border-bottom: 1px solid transparent;

  &:hover {
    color: var(--color-bright);
    border-bottom: 1px solid var(--color-bright);
  }
}

.artists {
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 34px;

  @include breakpoint($breakpoint1460) {
    gap: 13px;
  }

  @include breakpoint($breakpoint1350) {
    grid-template-columns: repeat(4, auto);
    gap: 60px;
  }

  @include breakpoint($breakpoint1040) {
    gap: 45px;
  }

  @include breakpoint($breakpoint950) {
    gap: 20px;
  }

  @include breakpoint($breakpoint900) {
    grid-template-columns: repeat(3, auto);
    gap: 34px;
  }

  @include breakpoint($breakpoint720) {
    gap: 15px;
  }

  @include breakpoint($breakpoint680) {
    grid-template-columns: repeat(2, auto);
    gap: 25px;
  }

  @include breakpoint($breakpoint450) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

.artist {
  filter: drop-shadow(0px 0px 134px rgba(0, 0, 0, 0.1));
}
