:global(#root) .root {
  --left-offset: 0%;
  --right-offset: 0%;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  .slider {
    margin-bottom: 40px;
    cursor: pointer;

    :global(.rc-slider-rail),
    :global(.rc-slider-step) {
      height: 100%;
      mask: url('./assets/mask.svg') no-repeat center;
      mask-size: 100%;
    }

    :global(.rc-slider-step) {
      background-color: var(--color-bright);
      clip-path: polygon(var(--left-offset) 0%, var(--right-offset) 0%, var(--right-offset) 100%, var(--left-offset) 100%);
    }

    :global(.rc-slider-track) {
      background-color: transparent;
    }

    :global(.rc-slider-handle) {
      position: static;
      width: 22px;
      height: 22px;
      border: 4px solid var(--color-light);
      background-color: var(--color-bright);
      box-shadow: none;
      opacity: 1;
    }

    :global(.rc-slider-mark-text) {
      padding-top: 2px;
      font-weight: 600;
      white-space: nowrap;
      color: var(--color-black);
    }
  }

  .pointer {
    position: absolute;
    top: -34px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .label {
    padding: 8px;
    border-radius: 8px;
    white-space: nowrap;
    background-color: var(--color-bright);
    color: var(--color-light);
    font-size: 12px;
  }
}
