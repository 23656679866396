@import 'styles/_mixins';
@import '../../Main.utils';
@import '../Statistics.utils';

$breakpoint1260: 1260px;

.root {
  padding: 150px 10px 150px 140px;
  display: flex;
  width: 100%;

  @include breakpoint($breakpoint1260) {
    padding: 100px 10px 100px 50px;
  }

  @include breakpoint-xl() {
    padding: 70px 10px 70px 50px;
  }

  @include breakpoint-lg() {
    padding: 70px 20px;
  }

  @include breakpoint($breakpoint800) {
    text-align: center;
    justify-content: center;
  }
}

.container {
  width: 530px;
  max-width: 100%;
}

.title {
  @include h2;

  color: var(--color-light);
  margin-bottom: 28px;

  @include breakpoint($breakpoint800) {
    margin-bottom: 20px;
    font-size: 35px;
  }

  @include breakpoint-sm() {
    font-size: 32px;
  }
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-light-grey);
  opacity: 0.6;
  margin-bottom: 56px;
  max-width: 100%;

  @include breakpoint($breakpoint800) {
    margin-bottom: 40px;
  }
}

.painted {
  color: #8e6f3a;
  background-image: linear-gradient(93deg, var(--color-bright), var(--color-dark) 130%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}

.stats {
  display: grid;
  gap: 56px;
  grid-template-columns: repeat(auto-fit, 130px);
  margin-bottom: 80px;

  @include breakpoint($breakpoint800) {
    gap: 30px;
    margin-bottom: 50px;
    justify-content: center;
  }

  @include breakpoint-lg() {
    gap: 20px;
  }
}

.head {
  font-weight: 700;
  font-size: 44px;
  color: var(--color-bright);
  line-height: 1.5;
  margin-bottom: 15px;
  position: relative;
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
}

.body {
  font-weight: 600;
  line-height: 1.5;
  color: var(--color-light);
}

.button {
  margin-right: 0 !important;
}
