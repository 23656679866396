@import 'styles/_mixins';

:global(#root ~ .modals) .root {
  max-width: 480px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    img {
      padding: 0 !important;
    }

    @include breakpoint-sm() {
      font-size: 12px;

      h3 {
        font-size: 14px;
      }
    }
  }

  .header {
    margin-bottom: 0;
  }

  .description {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    p {
      margin: 0;
    }
  }

  .section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    padding-left: 120px;
    border-radius: 13px;

    @include breakpoint-xs() {
      padding: 20px;
      padding-left: 90px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 40px;
      left: 40px;
      display: block;
      width: 60px;
      height: 60px;
      background-size: contain;

      @include breakpoint-xs() {
        top: 20px;
        left: 20px;
      }
    }
 
    &:nth-of-type(1) {
      background: linear-gradient(126deg, rgba(147, 152, 154, 0.5), #fefbfa), #FFFFFF;

      &::before {
        background-image: url('../../../assets/chrome.svg');
      }
    }

    &:nth-of-type(2) {
      background: linear-gradient(126deg, rgba(255, 163, 0, 0.3), #fefbfa), #FFFFFF;

      &::before {
        background-image: url('../../../assets/metamask.svg');
      }
    }
  }

  .link {
    padding: 10px 30px;
    border-radius: 30px;
    font-weight: 700;
    color: var(--color-light);
    background-color: var(--color-bright);

    @include breakpoint-xs() {
      padding: 10px;
    }
  }

  .button {
    padding: 10px 20px;
    box-shadow: 0 0 0 1px var(--color-bright) inset;
  }
}
