.root {
  position: relative;
  width: var(--card-width);
  height: 396px;
  border-radius: 20px;
  background-color: #c4c4c4;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.content {
  position: absolute;
  left: -2px;
  right: -2px;
  bottom: -2px;
  display: grid;
  grid-template-columns: 180px 80px;
  grid-template-rows: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 8px 18px 15px;
  border-radius: 0 0 20px 20px;
  backdrop-filter: blur(67px);
  background: rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 12px;
  color: var(--color-light-grey);
}

.title {
  min-height: 20px;
  font-size: 16px;
  font-weight: 700;
}

.icon {
  justify-self: flex-end;
  align-self: flex-end;
}
