:global(#root) .root {
  .title {
    font-size: 12px;
    font-weight: 500;
  }

  .statistics {
    width: max-content;
    margin-top: 0;
    text-align: left;
  }

  .value {
    font-size: 44px;
    font-weight: 700;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: var(--text-color);
  }
}
