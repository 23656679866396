@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 60px;
  background-color: var(--color-dark);
  color: var(--color-dark-grey);
  position: relative;

  @include breakpoint-md() {
    padding: 60px 40px;
  }

  &.largeSize {
    padding-top: 240px;
  }
}

.layout {
  @include layout;

  position: relative;
  z-index: 1;
}

.container {
  display: grid;
  grid-template-columns: 30% auto;
  grid-template-rows: repeat(2, auto);
  justify-content: space-between;
  column-gap: 80px;
  margin-bottom: 40px;

  :nth-child(3) {
    grid-row: 1/3;
    grid-column: 2;
  }

  @include breakpoint-xl() {
    justify-content: flex-start;
    column-gap: 40px;
  }

  @include breakpoint-lg() {
    column-gap: 80px;
  }

  @include breakpoint-md() {
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, auto);

    :nth-child(3) {
      grid-row: 2;
      grid-column: 1;
    }
  }
}

.logo {
  margin: 0;
  font-size: 26px;
  color: var(--color-light);

  span {
    color: var(--color-bright);
  }
}

.subscription {
  margin-top: 30px;
}

.label {
  cursor: pointer;
}

.socials {
  margin: 34px 0;

  .link {
    display: inline-block;
    margin-right: 12px;
    font-size: 20px;
  }
}

.link {
  color: var(--color-dark-grey);

  &:hover {
    color: var(--color-light);
  }
}

.input .inputIcon::before {
  top: 40% !important;
  width: 24px !important;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  background-color: var(--color-bright) !important;
  border-radius: 50%;
}

.termsAndPolicy {
  display: flex;
  gap: 30px;
  margin-bottom: 50px;
  padding: 35px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: rgba(101, 103, 107, 0.3);
}

.copyright {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  color: var(--color-light);

  @include breakpoint-xs() {
    flex-direction: column;
    align-items: center;
  }
}

.lightBall1 {
  position: absolute;
  right: 0;
  top: 200px;
  transform: translateX(60%);
}

.lightBall2 {
  position: absolute;
  right: -50px;
  top: -100px;
}
