@import 'styles/_mixins';
@import '../Main.utils';

.slider {
  margin-left: -110px;

  @include breakpoint-xxl() {
    margin-left: 0;
  }
}

.title {
  @include h2;

  color: var(--color-light);
  text-align: center;
}

.slide {
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.3));

  & > * {
    opacity: 0;
    transition-duration: 300ms;
  }

  @include breakpoint-xxl() {
    width: 340px !important;
    display: flex;
    justify-content: center;
  }

  @include breakpoint-lg() {
    width: 400px !important;

    & > * {
      opacity: 1;
    }
  }
}

.navigationContainerClass {
  transform: translateX(60px);

  @include breakpoint-xxl() {
    transform: translateX(0);
  }
}
