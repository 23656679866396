@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  max-width: 680px;
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;

  @include breakpoint-lg() {
    font-size: 16px;
  }

  @include breakpoint-sm() {
    font-size: 14px;
  }
}

.text {
  margin: 0;
  color: var(--color-dark-grey);

  span {
    color: var(--color-dark-card);
  }
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: var(--card-gap);
  row-gap: 24px;
}

:global(#root) .card {
  width: var(--card-width);
  margin: 0;
  padding: 16px;
  border: none;
  box-shadow: none;
  border-radius: 20px;
  text-align: center;

  :global(.image) {
    box-shadow: none;
  }

  :global(.content) {
    margin-top: 16px;
    border: none;
  }

  * {
    color: var(--color-dark-grey);
  }
}
