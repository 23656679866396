:global(#root) .root {
  &:global(.checkbox) {
    label {
      &::before {
        top: -4px;
        width: 48px;
        height: 24px;
        background-color: var(--color-light) !important;
      }

      &::after {
        left: 4px;
        width: 16px;
        height: 16px;
        background-color: #000 !important;
      }
    }
  }

  &:global(.checked) {
    label {
      &::before {
        background-color: var(--color-bright) !important;
      }

      &::after {
        left: 28px;
        background-color: var(--color-light) !important;
      }
    }
  }
}
