@import 'styles/_mixins';
@import '../OnSale.utils';

$breakpoint1460: 1460px;
$breakpoint1040: 1040px;

.root {
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: center;
  gap: var(--card-gap);

  @include breakpoint($breakpoint1460) {
    gap: 13px;
  }

  @include breakpoint-xxl() {
    grid-template-columns: repeat(3, auto);
    gap: 35px;
  }

  @include breakpoint($breakpoint1040) {
    gap: 11px;
  }

  @include breakpoint-lg() {
    gap: 40px;
    grid-template-columns: repeat(2, auto);
  }

  @include breakpoint-md() {
    gap: 11px;
  }

  @include breakpoint($breakpoint680) {
    gap: 30px;
    grid-template-columns: 1fr;
    justify-items: center;
    min-width: 80%;
  }
}

.good {
  filter: drop-shadow(0px 0px 134px rgba(0, 0, 0, 0.15));
}
