:global(#root) .root {
  input,
  :global(.label) {
    padding: 20px;
    border: none;
    border-radius: 20px;
    font-family: var(--font-family);
    font-size: 14px;
    background-color: rgba(217, 217, 217, 0.2);

    &::placeholder {
      color: var(--color-dark-grey);
    }
  }

  input[type=number]::-webkit-outer-spin-button, 
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  &Invalid {
    input,
    :global(.label) {
      background-color: var(--color-light-red);
    }
  }

  :global(.label) {
    display: flex;
    align-items: center;
    padding-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 400;
  }

  :global(.icon)::before {
    top: 45%;
    width: auto;
  }
}
